import { Add, Campaign, Create, Share, Visibility } from "@mui/icons-material";
import {
  Box,
  Container,
  Divider,
  Fab,
  Grid,
  Tab,
  Tabs,
  Typography,
} from "@mui/material";
import { Slide } from "modules/Layout/views/landing.view";
import Drawer from "modules/UI/components/Drawer.component";
import PUIRatioBox from "modules/UI/components/RatioBox.component";
import React, { useEffect, useRef } from "react";
import CreateCampaignContainer from "./create.container";
import { useQuery } from "@tanstack/react-query";
import CampaignsService from "../service/campaigns.service";
import { useNavigate } from "react-router-dom";
import CampaignCard from "modules/Campaigns/components/CampaignCard.component";
import { CampaignState } from "../service/campaigns.types";
import { PUIContentCarousel } from "modules/UI/components/ContentCarousel.component";
import { MaterialReactTable } from "material-react-table";
import CampaignsMaintenanceContainer from "./maintenance.container";
import { useActiveUser } from "modules/User/activeUser";
import { IUserRole } from "modules/User/service/user";

export const CampaignsListContainer = () => {
  const navigate = useNavigate();
  const currentUser = useActiveUser();
  const [createDialogOpen, setCreateDialogOpen] = React.useState(false);
  const [activeTab, setActiveTab] = React.useState(0);

  const { data: activeCampaigns, refetch: refetchActiveCampaigns } = useQuery({
    queryKey: ["campaigns/active"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
        state: CampaignState.Active,
      });
      return response;
    },
    initialData: { data: [], take: 100, skip: 0, total: 0 },
  });

  const { data: futureCampaigns, refetch: refetchFutureCampaigns } = useQuery({
    queryKey: ["campaigns/future"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
        state: CampaignState.Future,
      });
      return response;
    },
    initialData: { data: [], take: 100, skip: 0, total: 0 },
  });

  const { data: pastCampaigns, refetch: refetchPastCampaigns } = useQuery({
    queryKey: ["campaigns/past"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
        state: CampaignState.Past,
      });
      return response;
    },
    initialData: { data: [], take: 100, skip: 0, total: 0 },
  });

  const { data: draftCampaigns, refetch: refetchDraftCampaigns } = useQuery({
    queryKey: ["campaigns/draft"],
    queryFn: async () => {
      const response = await CampaignsService.getCampaigns({
        searchTerm: "",
        skip: 0,
        take: 100,
        state: CampaignState.Draft,
      });
      return response;
    },
    initialData: { data: [], take: 100, skip: 0, total: 0 },
  });

  const { data: archivedCampaigns, refetch: refetchArchivedCampaigns } =
    useQuery({
      queryKey: ["campaigns/archived"],
      queryFn: async () => {
        const response = await CampaignsService.getCampaigns({
          searchTerm: "",
          skip: 0,
          take: 100,
          state: CampaignState.Archived,
        });
        return response;
      },
      initialData: { data: [], take: 100, skip: 0, total: 0 },
    });

  return (
    <>
      <Drawer
        title="Create Campaign"
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
      >
        {createDialogOpen && (
          <CreateCampaignContainer onClose={() => setCreateDialogOpen(false)} />
        )}
      </Drawer>
      <Box sx={{ m: -3, mb: 3 }}>
        <PUIContentCarousel
          data={activeCampaigns?.data
            ?.sort(function (a, b) {
              return (
                new Date(a.created).getTime() - new Date(b.created).getTime()
              );
            })
            .map((campaign) => ({
              campaignId: campaign.id,
              type: "image",
              thumbnail: campaign.mediaFiles
                .filter((file) => file.mimeType.indexOf("image/") > -1)
                .sort(function (a, b) {
                  return (
                    new Date(a.uploadedOn).getTime() -
                    new Date(b.uploadedOn).getTime()
                  );
                })[0]?.url,
              src: campaign.mediaFiles
                .filter((file) => file.mimeType.indexOf("image/") > -1)
                .sort(function (a, b) {
                  return (
                    new Date(a.uploadedOn).getTime() -
                    new Date(b.uploadedOn).getTime()
                  );
                })[0]?.url,
            }))}
          onPreview={(slide) => {
            navigate(`/campaigns/${slide.campaignId}`);
          }}
        />
        {/* <PUIRatioBox xRatio={16} yRatio={9}>
          <Slide
            isActive={activeSlide === 0}
            backgroundImage="https://i.ytimg.com/vi/5n4J9duypeQ/maxresdefault.jpg"
            onNext={() => {
              setActiveSlide(1);
            }}
          />
          <Slide
            isActive={activeSlide === 1}
            backgroundImage="https://mir-s3-cdn-cf.behance.net/project_modules/fs/de70ae11079143.560f10407d139.jpg"
            onPrevious={() => {
              setActiveSlide(0);
            }}
          />
        </PUIRatioBox> */}
      </Box>
      {(currentUser.getUserRole() === IUserRole.PPA_ABOVE_MARKET ||
        currentUser.getUserRole() === IUserRole.ABOVE_MARKET) && (
        <Fab
          color="primary"
          variant="extended"
          sx={{ position: "fixed", bottom: 50, right: 50 }}
          onClick={() => setCreateDialogOpen(true)}
        >
          <Campaign sx={{ mr: 1 }} />
          New Campaign
        </Fab>
      )}

      <Container maxWidth="xl">
        <Grid container>
          <Grid item xs={12}>
            <Tabs
              value={activeTab}
              onChange={(event, newValue) => setActiveTab(newValue)}
              indicatorColor="primary"
              textColor="primary"
              variant="fullWidth"
              scrollButtons="auto"
              aria-label="scrollable auto tabs example"
              
            >
              <Tab sx={{ fontSize: 18 }} label={`Active Campaigns (${activeCampaigns?.total})`} />
              <Tab sx={{ fontSize: 18 }} label={`Upcoming Campaigns ${futureCampaigns?.total}`} />
              <Tab sx={{ fontSize: 18 }} label={`Past Campaigns ${pastCampaigns?.total}`} />
              <Tab sx={{ fontSize: 18 }} label={`Draft Campaigns ${draftCampaigns?.total}`} />
              <Tab sx={{ fontSize: 18 }} label={`Archived Campaigns ${archivedCampaigns?.total}`} />
            </Tabs>
          </Grid>
        </Grid>
      </Container>
      <Divider />

      <Container maxWidth="xl" sx={{py: 4}}>
        <Grid container spacing={4} alignItems={"stretch"}>
          {[
            activeCampaigns.data,
            futureCampaigns.data,
            pastCampaigns.data,
            draftCampaigns.data,
            archivedCampaigns.data,
          ][activeTab].map((campaign) => (
            <Grid item>
              <CampaignCard
                onRefetch={() => refetchPastCampaigns()}
                key={campaign.id}
                {...campaign}
                imageUrl={
                  campaign.mediaFiles
                    .filter((file) => file.mimeType.indexOf("image/") > -1)
                    .sort(function (a, b) {
                      return (
                        new Date(a.uploadedOn).getTime() -
                        new Date(b.uploadedOn).getTime()
                      );
                    })[0]?.url
                }
              />
            </Grid>
          ))}
        </Grid>
      </Container>
    </>
  );
};

export default CampaignsListContainer;
