import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import {
  BrowseGallery,
  Campaign,
  ChevronLeft,
  ChevronRight,
  Folder,
  Handshake,
  Home,
  Inbox,
  Mail,
  Menu as MenuIcon,
  Money,
  Payments,
  ShoppingBag,
  ShoppingCart,
  Store,
  Task,
    Collections
} from "@mui/icons-material";
import {
  Box,
  CssBaseline,
  Toolbar,
  IconButton,
  Typography,
  Drawer as MuiDrawer,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  styled,
  useTheme,
  CSSObject,
  Theme,
  Button,
  Tooltip,
  ListItemAvatar,
} from "@mui/material";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import React, { ReactElement, useEffect, useState } from "react";
import { loginRequest } from "auth/authConfig";
import { ReactComponent as Logo } from "assets/s2p_logo.svg";
import { PUIAvatar } from "modules/UI/components/Avatar.component";
import { NavLink, Outlet, useNavigate } from "react-router-dom";
import { useActiveUser } from "modules/User/activeUser";
import ErrorBoundary from "modules/UI/components/ErrorBoundary.component";
import EasyRequestCart from "modules/EasyRequest/components/EasyRequestCart.component";
import UserSettingsDrawer from "modules/User/components/UserSettingsDrawer.component";

export type NavigationMenuItem = {
  name?: string;
  uri?: string;
  icon?: ReactElement;
  badgeContent?: number;
  feature?: string;
  dataCy?: string;
  hidden?: boolean;
  tourId?: string;
  isComingSoon?: boolean;
  isExternal?: boolean;
  customTag?: string;
  isSeparator?: boolean;
};

const drawerWidth = 240;

const openedMixin = (theme: Theme): CSSObject => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  background: theme.palette.primary.main,
  color: theme.palette.primary.contrastText,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});
const StyledDrawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  color: theme.palette.primary.contrastText,
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));
interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  background: theme.palette.background.paper,
  color: theme.palette.text.primary,
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));
export const Layout = (props) => {
  const theme = useTheme();
  const { instance, inProgress } = useMsal();
  const navigate = useNavigate();
  const isAuthenticated = useIsAuthenticated();
  const activeUser = useActiveUser();
  const [open, setOpen] = React.useState(false);
  const [profileOpen, setProfileOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const navItems: NavigationMenuItem[] = [
    {
      name: "Home",
      uri: "/",
      icon: <Home />,
    },
    {
      name: "Browse",
      uri: "/browse",
      icon: <ShoppingBag />,
    },
    {
      name: "Suppliers",
      uri: "/suppliers",
      icon: <Store />,
    },
    {
      name: "Briefs",
      uri: "/briefs",
      isComingSoon: true,
      icon: <Handshake />,
    },
    {
      name: "Campaigns",
      uri: "/campaigns",
      isComingSoon: false,
      icon: <Campaign />,
    },
  ];

  if (activeUser.hasAboveMarketRole()) {
    navItems.push({
      name: "Promo categories",
      uri: "/admin/promo-categories",
      icon: <Collections />,
    });
  }

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <UserSettingsDrawer
        open={profileOpen}
        onClose={() => setProfileOpen(false)}
      />
      <StyledDrawer variant="permanent" open={open}>
        <DrawerHeader>
          {/* <img src={logo} style={{ marginRight: "auto" }} /> */}
          <Logo />
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRight /> : <ChevronLeft />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {navItems.map((item, index) =>
            item.isSeparator ? (
              <Divider key={"divider" + index} />
            ) : (
              <Tooltip
                title={`${item.isComingSoon ? " Coming Soon: " : ""}${
                  item.name
                }`}
                placement="right"
                arrow
              >
                <ListItem
                  key={item.name + item.uri}
                  disablePadding
                  sx={{ display: "block" }}
                >
                  <ListItemButton
                    disabled={item.isComingSoon}
                    component={NavLink}
                    to={item.uri}
                    sx={{
                      minHeight: 48,
                      justifyContent: open ? "initial" : "center",
                      px: 2.5,
                    }}
                  >
                    <ListItemIcon
                      sx={{
                        color: "inherit",
                        minWidth: 0,
                        mr: open ? 3 : "auto",
                        justifyContent: "center",
                      }}
                    >
                      {item.icon}
                    </ListItemIcon>
                    <ListItemText
                      primary={item.name}
                      sx={{ opacity: open ? 1 : 0 }}
                    />
                  </ListItemButton>
                </ListItem>
              </Tooltip>
            )
          )}
        </List>
      </StyledDrawer>
      <AppBar position="fixed" elevation={0} open={open}>
        <Toolbar sx={{ gap: theme.spacing(2), borderBottom: "1px solid #ddd" }}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 2,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>

          <Typography variant="h6" noWrap sx={{ marginRight: "auto" }}>
            Promo Catalog
          </Typography>

          {isAuthenticated ? (
            <>
              {!(
                activeUser.isRequestor() ||
                activeUser.isFactoryAnalyst() ||
                activeUser.isViewOnly()
              ) && (
                <>
                  <Divider
                    orientation="vertical"
                    flexItem
                    sx={{ ml: "auto" }}
                  />
                  <Tooltip title="MyTasks" arrow>
                    <IconButton
                      onClick={(e) => {
                        navigate("/tasks");
                      }}
                    >
                      {/* <Badge badgeContent={4} color="secondary"> */}
                      <Task />
                      {/* </Badge> */}
                    </IconButton>
                  </Tooltip>
                </>
              )}
              <Divider orientation="vertical" flexItem />
              <EasyRequestCart />
              <Divider orientation="vertical" flexItem />
              <ListItemButton
                dense
                sx={{ width: "auto", flexGrow: 0 }}
                onClick={(e) => setProfileOpen(true)}
              >
                <ListItemAvatar>
                  <PUIAvatar src={activeUser.photoUrl} />
                </ListItemAvatar>
                <ListItemText
                  primary={activeUser.name}
                  secondary={activeUser.userType}
                />
              </ListItemButton>
              {/* <Menu
                id="basic-menu"
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={handleClose}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <UserRoleSwitcher />
                <Divider />
                <MenuItem onClick={handleClose}>S2P Browser</MenuItem>
                <MenuItem onClick={handleClose}>Supplier Portal</MenuItem>
                <MenuItem onClick={handleClose}>Logout</MenuItem>
              </Menu> */}
            </>
          ) : (
            <Button
              sx={{ marginLeft: "auto" }}
              variant="contained"
              color="primary"
              onClick={() => {
                instance
                  .loginRedirect(loginRequest)
                  .catch((e) => console.error(e));
              }}
            >
              Login
            </Button>
          )}
        </Toolbar>
      </AppBar>

      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          maxWidth: `calc(100% - ${open ? drawerWidth : 65}px)`,
          minHeight: "100vh",
        }}
      >
        <DrawerHeader />{" "}
        {/** this guy is pushing down the content to prevent the app bar from hiding it */}
        <ErrorBoundary>
          <Outlet />
        </ErrorBoundary>
      </Box>
    </Box>
  );
};
