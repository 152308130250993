import React, { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { AxiosWrapper } from "auth/axios.wrapper";
import { createBlobService } from "utils/factories";

export const AuthenticatedImage = (props: {
  src: string;
  [key: string]: any;
}) => {
  const { src, ...otherProps } = props;
  const [url, setUrl] = useState(null);

  useEffect(() => {
    createBlobService(props.src, "GET")().then((blob) =>
      setUrl(URL.createObjectURL(blob))
    );
  }, [props.src]);

  return <img src={url} alt="" {...otherProps} />;
};

export const AuthenticatedImageBackgroundDiv = (props: {
  src: string;
  style?: HTMLAttributes<HTMLDivElement>["style"];
  children?: React.ReactNode;
}) => {
  const { src, style, ...otherProps } = props;
  const [url, setUrl] = useState(null);

  useEffect(() => {
    createBlobService(props.src, "GET")().then((blob) =>
      setUrl(URL.createObjectURL(blob))
    );
  }, [props.src]);

  return (
    <div
      style={{
        backgroundImage: `url(${url})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        ...style,
      }}
    >
      {props.children}
    </div>
  );
};

// WIP
// export const AuthenticatedBlobComponent = (props: {
//   url: string;
//   propName: string;
// }) => {
//   const { url, propName, ...otherProps } = props;
//   const [blob, setBlob] = useState(null);

//   useEffect(() => {
//     createBlobService(props.url, "GET")().then((blob) => setBlob(blob));
//   }, [props.url]);

//   return (
//     <div
//       style={{
//         backgroundImage: `url(${url})`,
//         backgroundSize: "cover",
//         backgroundPosition: "center",
//         ...style,
//       }}
//     >
//       {props.children}
//     </div>
//   );
// };
