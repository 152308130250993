import { TextField, TextFieldProps } from "@mui/material";
import { useField } from "formik";
import React from "react";

export const TextFieldWrapper = (
  props:
    | TextFieldProps
    | {
        onChange: any;
        name: string;
      }
) => {
  const { name, onChange, ...otherProps } = props;
  const [field, meta, helpers] = useField(props.name);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    helpers.setValue(e.target.value);
    if (onChange) {
      onChange(e);
    }
  };

  return (
    <TextField
      {...otherProps}
      value={field.value}
      onChange={handleChange}
      helperText={meta.error}
      error={!!meta.error}
    />
  );
};

export default TextFieldWrapper;
