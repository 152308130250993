import moment from "moment";
import { Container, Grid, Tooltip } from "@mui/material";
import React, { useState } from "react";
import {
  ActivityLogDTO,
  ActivityLogType,
} from "modules/Campaigns/service/campaigns.types";

export const ActivityLogInitiatorUserTooltip = ({
  email,
  name,
}: {
  email: string;
  name: string;
}) => {
  const [tooltip, setTooltip] = useState(email);

  const onClick = () => {
    // eslint-disable-next-line no-undef
    navigator.clipboard.writeText(email);
    setTooltip("Copied to clipboard");

    setTimeout(() => {
      setTooltip(email);
    }, 1500);
  };

  return (
    <Tooltip arrow title={tooltip}>
      <strong onClick={onClick}>{name}</strong>
    </Tooltip>
  );
};

export const ActivityLog = ({ entries }: { entries: ActivityLogDTO[] }) => {
  return (
    <Container sx={{ p: 4 }}>
      {entries
        .sort((a, b) =>
          moment.utc(a.createdOn).isAfter(moment.utc(b.createdOn)) ? -1 : 1
        )
        .map((a, index) => {
          return (
            <Grid
              sx={{ mb: 2 }}
              spacing={4}
              container
              justifyContent={"space-between"}
              key={index}
            >
              <Grid item>
                <div>
                  {a.createdBy && (
                    <ActivityLogInitiatorUserTooltip
                      name={a.createdBy.split("@")[0].split(".").join(" ")}
                      email={a.createdBy}
                    />
                  )}
                  {!a.createdBy && <span className="pr-1 italic">System</span>}
                  <span>
                    {" "}
                    {
                      {
                        [ActivityLogType.OrderWindowCreated]:
                          "created campaign",
                        [ActivityLogType.OrderWindowUpdated]:
                          "updated campaign",
                        [ActivityLogType.OrderWindowDeleted]:
                          "deleted campaign",
                        [ActivityLogType.OrderWindowSubscriberAdded]:
                          "added subscriber",
                        [ActivityLogType.OrderWindowSubscriberRemoved]:
                          "removed subscriber",
                      }[a.type]
                    }
                  </span>
                </div>
                <span className="italic text-sm">
                  {a.additionalInformation}
                </span>
              </Grid>

              <Grid item>
                <Tooltip
                  arrow
                  title={moment(a.createdOn).format("DD/MM/YYYY h:mm a")}
                >
                  <span>{moment.utc(a.createdOn).fromNow()}</span>
                </Tooltip>{" "}
              </Grid>
            </Grid>
          );
        })}
    </Container>
  );
};

export default ActivityLog;
