import {Add, DeleteOutline, EditOutlined, FileCopyOutlined, Remove,} from "@mui/icons-material";
import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardActionArea,
  CardContent,
  Divider,
  FormHelperText,
  Grid,
  ListSubheader,
  MenuItem,
  Select,
  Tooltip,
} from "@mui/material";
import React, {useState} from "react";
import {toast} from "react-toastify";
import {CampaignState} from "../service/campaigns.types";
import {useJustification} from "modules/UI/hooks/useJustification.hook";
import {PUIContentCarousel} from "modules/UI/components/ContentCarousel.component";
import PUIRatioBox from "modules/UI/components/RatioBox.component";
import Dropzone from "modules/Forms/components/dropzone.component";
import {DropEvent, FileRejection} from "react-dropzone";
import * as yup from "yup";
import {useQuery} from "@tanstack/react-query";
import {UserService} from "modules/User/service/user.service";
import {useActiveUser} from "modules/User/activeUser";
import CampaignsService from "../service/campaigns.service";
import {MediaFileSchema} from "../service/campaigns.schemas";
import TextFieldWrapper from "modules/Forms/components/textFIeldWrapper.component";

export const CampaignMaterialCard = (props) => {
  const { material, campaign, requests, onRefetch } = props;
  const currentUser = useActiveUser();
  const [quantity, setQuantity] = useState(material.minimumOrderQuantity);
  const { data: userFullScope } = useQuery({
    queryKey: ["userFullScope"],
    queryFn: async () => {
      const result = await UserService.fetchUserFullScope();
      return result;
    },
    initialData: [],
  });
  const requestQuote = useJustification({
    title: `Your order`,
    description: "Your comments",
    submitButtonText: `Submit`,
    additionalData: [
      {
        key: "quantity",
        initialValue: quantity,
        validationSchema: yup
          .number()
          .required()
          .min(material.minimumOrderQuantity),
      },
      {
        key: "mediaFiles",
        initialValue: [],
        validationSchema: yup.array().of(MediaFileSchema).notRequired(),
      },
    ],
    additionalContent: ({ values, setFieldValue }) => (
      <div>
        <Grid
          container
          spacing={2}
          justifyContent={"space-between"}
          alignItems={"center"}
          sx={{ mt: 2 }}
        >
          <Grid item xs={12}>
            <TextFieldWrapper name="quantity" label="Quantity" fullWidth />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={12}>
            <Dropzone
              placeholder={
                "You can optionaly attach a file by dropping it here"
              }
              fileName={values.mediaFiles[0]?.originalFileName}
              onRemoveFile={
                values.mediaFiles.length === 0
                  ? null
                  : (fileName) => {
                      CampaignsService.deleteQuantityRequestMedia({
                        fileId: values.mediaFiles[0]?.fileId,
                      }).then(() => {
                        setFieldValue("mediaFiles", []);
                      });
                    }
              }
              onDrop={async function <T extends File>(
                acceptedFiles: T[],
                fileRejections: FileRejection[],
                event: DropEvent
              ): Promise<void> {
                CampaignsService.uploadQuantityRequestMedia({
                  file: acceptedFiles[0],
                }).then((result) => {
                  setFieldValue("mediaFiles", [result]);
                });
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item xs={6}>
            Location
          </Grid>
          <Grid item xs={6}>
            <Select
              fullWidth
              size="small"
              value={values.market?.id}
              onChange={(e) => {
                setFieldValue("market", {
                  id: e.target.value,
                  name: e.target.value,
                });
              }}
            >
              {userFullScope
                .filter((zoneScope) => {
                  return zoneScope.markets.some((marketScope) => {
                    return !!campaign.markets.find(
                      (m) => m.id === marketScope.market.id
                    );
                  });
                })
                .map((zoneScope) => {
                  return [
                    <ListSubheader>---{zoneScope.zone.name}---</ListSubheader>,
                    zoneScope.markets
                      .filter((marketScope) => {
                        return !!campaign.markets.find(
                          (m) => m.id === marketScope.market.id
                        );
                      })
                      .map((marketScope) => (
                        <MenuItem
                          key={marketScope.market.id}
                          value={marketScope.market.id}
                          disabled={
                            requests?.requests
                              .map((req) => req.market.id)
                              .indexOf(marketScope.market.id) > -1
                          }
                        >
                          {marketScope.market.name}{" "}
                          {requests?.requests
                            .map((req) => req.market.id)
                            .indexOf(marketScope.market.id) > -1 ? (
                            <> - already requested</>
                          ) : (
                            <></>
                          )}
                        </MenuItem>
                      )),
                  ];
                })}
              <MenuItem />
            </Select>
          </Grid>
        </Grid>
      </div>
    ),
  });
  return (
    <>
      {requestQuote.JSX}
      <Card sx={{ width: "100%" }}>
        <Box sx={{ width: "100%" }}>
          {material.mediaFiles.length > 0 ? (
            <PUIContentCarousel
              data={material.mediaFiles.map((file) => ({
                type: "image",
                thumbnail: file.url,
                src: file.url,
              }))}
              // onPreview={() => {}}
            />
          ) : (
            <PUIRatioBox xRatio={16} yRatio={9}>
              <></>
              {/* <Avatar
              variant="square"
              sx={{
                width: "100%",
                height: "100%",
                // background: "linear-gradient(112.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(157.5deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(135deg, rgb(214, 214, 214) 0%, rgb(214, 214, 214) 10%,rgb(195, 195, 195) 10%, rgb(195, 195, 195) 53%,rgb(176, 176, 176) 53%, rgb(176, 176, 176) 55%,rgb(157, 157, 157) 55%, rgb(157, 157, 157) 60%,rgb(137, 137, 137) 60%, rgb(137, 137, 137) 88%,rgb(118, 118, 118) 88%, rgb(118, 118, 118) 91%,rgb(99, 99, 99) 91%, rgb(99, 99, 99) 100%),linear-gradient(90deg, rgb(195, 195, 195),rgb(228, 228, 228)); background-blend-mode:overlay,overlay,overlay,multiply",
                backgroundColor: promoCategory?.color || "#ccc",
                backgroundImage:
                  "linear-gradient(0deg, rgba(0,0,0,1), rgba(255,255,255,.1))",
                backgroundBlendMode: "overlay",
                "& svg": {
                  opacity: 0.2,
                  width: "50%",
                  height: "50%",
                },
              }}
            >
              {promoCategory ? <promoCategory.icon /> : <></>}
            </Avatar> */}
            </PUIRatioBox>
          )}
        </Box>
        <CardContent>{material.shortDescription}</CardContent>
        <CardActionArea>
          {campaign.state === CampaignState.Draft && (
            <Box textAlign={"center"} sx={{ mb: 2 }}>
              <ButtonGroup variant="outlined" color="primary">
                <Tooltip arrow title="Delete material">
                  <Button onClick={() => props.onRemove?.(material)}>
                    <DeleteOutline />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Duplicate material">
                  <Button onClick={() => props.onDuplicate?.(material)}>
                    <FileCopyOutlined />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Edit material">
                  <Button onClick={() => props.onEdit?.(material)}>
                    <EditOutlined />
                  </Button>
                </Tooltip>
              </ButtonGroup>
            </Box>
          )}
          {campaign.state === CampaignState.Active && (
            <Box textAlign={"center"}>
              <ButtonGroup variant="outlined" color="primary">
                <Tooltip arrow title="Decrease quantity">
                  <Button
                    disabled={quantity <= material.minimumOrderQuantity}
                    onClick={() => {
                      setQuantity((prev) => prev - material.minimumPurchaseQuantity * material.packQuantity);
                    }}
                  >
                    <Remove />
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Order">
                  <Button
                    size="large"
                    variant="contained"
                    onClick={() => {
                      requestQuote
                        .requestJustification()
                        .then((data) => {
                          CampaignsService.requestMaterialQuantity({
                            ...data,
                            requestor: currentUser.email,
                            comments: data.justification,
                            quantity: data.quantity,
                            orderWindowMaterialId: material.id,
                          })
                            .then((result) => {
                              toast.success("Pre-order was successful.");
                              if (onRefetch) onRefetch();
                            })
                            .catch((e) => {
                              toast.success("Something went wrong.");
                              if (onRefetch) onRefetch();
                            });
                          setQuantity(material.minimumOrderQuantity);
                        })
                        .catch(() => {
                          setQuantity(material.minimumOrderQuantity);
                          if (onRefetch) onRefetch();
                        });
                    }}
                  >
                    Order {quantity} {material.unitOfMeasure}
                  </Button>
                </Tooltip>
                <Tooltip arrow title="Increase quantity">
                  <Button
                    onClick={() => {
                      setQuantity((prev) => prev + material.minimumPurchaseQuantity * material.packQuantity);
                    }}
                  >
                    <Add />
                  </Button>
                </Tooltip>
              </ButtonGroup>
              <FormHelperText sx={{ textAlign: "center" }}>
                est. cost{" "}
                {quantity *
                  [...material.priceRanges]
                    .sort((a, b) => {
                      return a.threshold < b.threshold ? 1 : -1;
                    })
                    .find((r) => quantity >= r.threshold)?.unitPrice}{" "}
                {material.currencyCode}
              </FormHelperText>
            </Box>
          )}
        </CardActionArea>
      </Card>
    </>
  );
};
