import { Alert, AlertTitle, Grid, Link, Typography } from "@mui/material";
import Dropzone from "modules/Forms/components/dropzone.component";
import React, { FC, useEffect } from "react";
import { FileRejection, DropEvent } from "react-dropzone";
import PromoCatalogService from "service/PromoCatalog.service";
import PromoItemsService from "../services/promoItems.service";
import { useMutation } from "@tanstack/react-query";
import { saveAs } from "file-saver";
import { LightbulbOutlined } from "@mui/icons-material";
import { toast } from "react-toastify";

class DownloadCompletedAction {
  private openLink(fileName, url) {
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", fileName);
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    // hack for IE11
    if (typeof link.remove === "function") {
      link.remove();
    } else link.parentNode.removeChild(link);
  }

  constructor(public fileName, public data) {
    if (data instanceof Blob) {
      // @ts-ignore
      if (window.navigator.msSaveOrOpenBlob) {
        // @ts-ignore
        window.navigator.msSaveBlob(data, fileName);
      } else {
        this.openLink(fileName, window.URL.createObjectURL(data));
      }
    } else {
      this.openLink(fileName, data);
    }
  }
}

export type ImportPromoItemsContainerProps = {
  onClose?: (response: any) => void;
};

export const ImportPromoItemsContainer: FC<ImportPromoItemsContainerProps> = (
  props
) => {
  const { mutate: upload, status } = useMutation({
    mutationKey: ["uploadPromoItems"],
    mutationFn: async (file: File) => {
      const response = await PromoItemsService.massImportMaterials({ file });
      toast.success(
        "Your data is being uploaded. You will be notified via email when the data upload will be completed"
      );
      if (props.onClose) props.onClose(response);
    },
  });

  const { mutate: download, isPending } = useMutation({
    mutationKey: ["downloadPslTemplate"],
    mutationFn: async () => {
      const response = await PromoItemsService.getPSLTemplate();
      saveAs(response, "importPslTemplate.xlsx");
    },
  });

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Dropzone
            uploadStatus={status}
            placeholder={"Drag and drop your file here"}
            maxFiles={1}
            maxSize={1000000}
            accept={{
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                [".xlsx"],
            }}
            multiple={false}
            onDrop={async function <T extends File>(
              acceptedFiles: T[],
              fileRejections: FileRejection[],
              event: DropEvent
            ): Promise<void> {
              if (acceptedFiles.length > 0) {
                upload(acceptedFiles[0]);
              }
            }}
          />
        </Grid>
        <Grid item xs={12} textAlign={"center"}>
          Download{" "}
          <Link
            sx={{ cursor: isPending ? "wait" : "pointer" }}
            onClick={() => (!isPending ? download() : null)}
          >
            sample xlsx file
          </Link>{" "}
          and compare it to your import file to ensure you have the file perfect
          for the import
        </Grid>
        <Grid item xs={12}>
          <Alert severity="info" icon={<LightbulbOutlined />}>
            <AlertTitle>Page Tips</AlertTitle>
            You can download the sample xlsx file to get detailed information
            about the data fields used while importing. If you have files in
            other formats, you can convert it to an accepted file format using
            any online/offline converter. You can configure your import settings
            and save them for future too!
          </Alert>
        </Grid>
      </Grid>
    </>
  );
};

export default ImportPromoItemsContainer;
