import React from "react";
import Dialog from "@mui/material/Dialog";
import Barcode from "react-barcode";
import { IconButton } from "@mui/material";

export const BarcodeButton = (props: { value: string; disabled?: boolean }) => {
  const [barcodeOpen, setBarcodeOpen] = React.useState(false);
  return (
    <>
      <Dialog open={barcodeOpen} onClose={() => setBarcodeOpen(false)}>
        <Barcode value={props.value} />
      </Dialog>
      <IconButton
        onClick={() => setBarcodeOpen(true)}
        size="small"
        disabled={props.disabled}
      >
        <svg
          width="1em"
          height="1em"
          viewBox="0 0 123 98"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M2.38,0l18.33,0l-0,4.76l-15.95,0l0,12.44l-4.76,0l0,-14.82c0,-1.31 1.07,-2.38 2.38,-2.38Zm15.54,16.23l8.26,0l0,64.58l-8.26,0l0,-64.58Zm51.49,-0l5.9,-0l0,64.58l-5.9,0l0,-64.58Zm-11.43,-0l4.42,-0l0,64.58l-4.42,0l0,-64.58Zm-24.79,-0l2.51,-0l0,64.58l-2.51,0l0,-64.58Zm64.4,-0l7.37,-0l0,64.58l-7.37,0l0,-64.58Zm-15.27,-0l8.26,-0l-0,64.58l-8.26,0l-0,-64.58Zm-39.61,-0l8.26,-0l-0,64.58l-8.26,0l-0,-64.58Zm-37.95,63.61l-0,12.44l15.95,0l-0,4.76l-18.33,0c-1.31,0 -2.38,-1.06 -2.38,-2.38l-0,-14.82l4.76,0Zm98.64,-79.84l17.1,0c1.31,0 2.38,1.07 2.38,2.38l0,14.82l-4.76,0l0,-12.44l-14.72,0l0,-4.76Zm19.48,79.84l0,14.82c0,1.31 -1.07,2.38 -2.38,2.38l-17.1,0l0,-4.76l14.72,0l0,-12.44l4.76,0Z" />
        </svg>
      </IconButton>
    </>
  );
};
