import { AccountCircle, Delete } from "@mui/icons-material";
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import CampaignsService from "../service/campaigns.service";
import { useActiveUser } from "modules/User/activeUser";
import { useConfirmation } from "modules/UI/hooks/useConfirmation.hook";

export const SubscribersContainer = (props: {
  subscribers: { name: string; email: string; campaignId: string | number }[];
  onUnsubscribe: (email: string) => void;
  readOnly?: boolean;
}) => {
  const currentUser = useActiveUser();
  const unsubscribeConfirmation = useConfirmation({
    title: "Unsubscribe from campaign notifications",
    description: "Are you sure you want to unsubscribe from this campaign?",
    cancelButtonText: "Cancel",
    submitButtonText: "Yes, unsubscribe",
  });
  return (
    <>
      {unsubscribeConfirmation.JSX}
      <List>
        {props.subscribers.map((subscriber) => (
          <ListItem
            key={subscriber.email}
            secondaryAction={
              subscriber.email === currentUser?.email ? (
                <Button
                  disabled={!!props.readOnly}
                  startIcon={<Delete />}
                  onClick={() => {
                    unsubscribeConfirmation
                      .requestConfirmation()
                      .then(() => {
                        props.onUnsubscribe(subscriber.email);
                      })
                      .catch((reason) =>
                        console.info("Unsubscribe cancelled", reason)
                      );
                  }}
                >
                  Unsubscribe
                </Button>
              ) : null
            }
          >
            <ListItemAvatar>
              <AccountCircle />
            </ListItemAvatar>
            <ListItemText
              primary={subscriber.name}
              secondary={subscriber.email}
            />
          </ListItem>
        ))}
      </List>
    </>
  );
};
