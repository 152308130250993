import {
  IdNamePair,
  IdTitlePair,
  SupplierContactType,
} from "service/generic.types";
import { InferType } from "yup";
import {
  CampaignMaterialSchema,
  CreateCampaignSchema,
  MediaFileSchema,
} from "./campaigns.schemas";

export type ShortUserType = {
  id: string;
  email: string;
  username: string;
};

export enum CampaignStatus {
  Draft,
  Active,
  Archived,
}

export enum CampaignState {
  Draft,
  Active,
  Future,
  Past,
  Archived,
}

export type MediaFileDTO = InferType<typeof MediaFileSchema>;

export type CreateCampaignDTO = InferType<typeof CreateCampaignSchema>;

export type CampaignShortDTO = {
  validTo?: string;
  id: number;
  name: string;
  description: string;
  agreements?: string;
  startDate: string;
  endDate: string;
  supplierCode: string;
  supplierName: string;
  isDeleted: boolean;
  status: CampaignStatus;
  supplierContact?: Omit<SupplierContactType, "firstname" | "lastname"> & {
    username: string;
  };
  state: CampaignState;
  created: string;
  modified: string;
  publishErrors: { code: string; message: string }[];
  orderWindowFilesIds: string[];
  mediaFiles: MediaFileDTO[];
  createdByUser: ShortUserType;
  modifiedByUser: ShortUserType;
  zones: IdNamePair<number>[];
  markets: IdNamePair<number>[];
  companyCodes: (IdNamePair<number> & { code: string })[];
};

export type CampaignMaterialType = InferType<typeof CampaignMaterialSchema>;

export enum ActivityLogType {
  OrderWindowCreated = 0,
  OrderWindowDeleted = 1,
  OrderWindowUpdated = 2,
  OrderWindowSubscriberAdded = 3,
  OrderWindowSubscriberRemoved = 4,
}

export type ActivityLogDTO = {
  orderWindowId: number;
  type: ActivityLogType;
  additionalInformation: string;
  createdBy: string;
  createdOn: string;
  id: number;
};
