import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useQuery } from "@tanstack/react-query";
import {
  CustomFastAsyncSelect,
  CustomFastSelect,
} from "modules/Forms/components/form.legacy.component";
import PromoItemsService from "modules/PromoItems/services/promoItems.service";
import Drawer from "modules/UI/components/Drawer.component";
import moment from "moment";
import { flatten } from "ramda";
import React, { useEffect, useState } from "react";
import { SupplierDiversityFilterComponent } from "../components/supplierDiversityFilter.component";
import {
  WaysOfBuyingComponent,
  waysTreeData,
} from "modules/PromoItems/components/WaysOfBuying.component";
// import { MuiChipsInput } from "mui-chips-input/dist/mui-chips-input.es";
import { ScopeFilterComponent } from "modules/PromoItems/components/ScopeFilter.component";
import { useActiveUser } from "modules/User/activeUser";
import ItemsCounter from "modules/UI/components/ItemsCounter.component";
import { UserService } from "../../User/service/user.service";
import { LocationComponent } from "../components/locationFilter.component";

export const defaultFiltersState = {
  // spendCatL1s: [],
  // spendCatL2s: [],
  // spendCatL3s: [],
  companies: [],
  markets: [],
  statuses: [],
  complianceStatuses: [],
  suppliers: [],
  materials: [],
  businessUnits: [],
  strategicBuyers: [],
  pslStatuses: [],
  supplierDiversity: [],
  wayOfBuying: [],
  scope: [],
  time: "active",
  exactId: null,
  taskKind: null,
  createdByUserId: null,
  fromSpend: null,
  toSpend: null,
  childrenOf: null,
  currencySpend: null,
  plants: [],
  orderBy: null,
  pslCreationAndValidityDate: null,
  zones: [],
  pslIds: [],
  pslSearch: null,
  materialSearch: null,
  showObsolete: false,
  FavoriteSwitchOn: false,
  supplierDescription: [{ id: "parentVendor", title: "Parent Vendor" }],
};

export const PslFiltersContainer = (props) => {
  const { initialData, open, onClose, onApply, onClear } = props;
  const activeUser = useActiveUser();
  const [companyCodes, setCompCodes] = React.useState([]);
  const [marketState, setMarketState] = React.useState([]);
  const [zoneState, setZoneState] = React.useState([
    { id: 1, name: "NORTH AMERICA" },
    { id: 2, name: "LATAM" },
    { id: 3, name: "EMENA" },
    { id: 4, name: "AOA" },
  ]);
  useEffect(() => {
    UserService.fetchUserScope(false).then((res: any) => {
      const markets =
        activeUser.filters?.userScope?.markets.map((it) => it.id) ?? [];
      const marketsInScope = activeUser.filters?.userScope?.markets ?? [];
      if (markets.length > 0) {
        const marketItems = res.filter(
          (item: { market: { id: { toString: () => string | number } } }) =>
            markets.includes(item.market.id.toString())
        );
        setCompCodes(
          flatten(
            marketItems.map((item: { companyCodes: any }) => item.companyCodes)
          )
        );
        setMarketState(marketsInScope);
      } else {
        setCompCodes(
          flatten(res.map((item: { companyCodes: any }) => item.companyCodes))
        );
        setMarketState(
          res.map((item) => {
            return {
              id: item.market.id.toString(),
              title: item.market.name,
            };
          })
        );
      }
    });
  }, []);
  const { data: businessUnits, isLoading: loadingBusinessUnits } = useQuery({
    queryKey: ["businessUnits"],
    queryFn: () => PromoItemsService.fetchBusinessUnits(),
    initialData: [],
  });
  const { data: currencies, isLoading: loadingCurrencies } = useQuery({
    queryKey: ["currencies"],
    queryFn: () => PromoItemsService.fetchCurrencies(),
    initialData: [],
  });
  const [state, setState] = useState({
    ...defaultFiltersState,
    ...initialData,
  });

  return (
    <Drawer
      title="Filters"
      open={open}
      onClose={onClose}
      footerContent={
        <Grid container spacing={2} justifyContent={"flex-end"}>
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              size="large"
              onClick={() => {
                setState({ ...defaultFiltersState /*, ...initialData*/ });
              }}
            >
              Reset
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={() => {
                onApply(state);
              }}
            >
              Apply
            </Button>
          </Grid>
        </Grid>
      }
    >
      {
        //Scope
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Scope
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.scope?.map((v) => v.title)}
              singularName="option"
              pluralName="options"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <ScopeFilterComponent
            user={activeUser}
            initialValue={state.scope?.map((v) => v.id)}
            onChange={(newValue) => {
              setState((prevState) => ({
                ...prevState,
                scope: newValue.map((v) => ({
                  id: v,
                  title: v,
                })),
              }));
            }}
          />
        </AccordionDetails>
      </Accordion>
      {
        //Material Number
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Material Number
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.materials?.map((m) => `[${m.id}] ${m.title} `)}
              singularName="material"
              pluralName="materials"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomFastAsyncSelect
            onLoad={async (searchTerm) => {
              const result = await PromoItemsService.getPslAmdrMaterials({
                searchTerm,
              });
              return result.map(({ id, name }) => ({
                value: id,
                label: name,
              }));
            }}
            isMulti
            customDisplay={(option) => `[${option.value}] ${option.label}`}
            onLoadLimit={3}
            initialValue={state.materials?.map((m) => ({
              value: m.id,
              label: m.title,
            }))}
            onChange={(value) => {
              setState((prevState) => ({
                ...prevState,
                materials: value?.map((v) => ({ id: v.value, title: v.label })),
              }));
            }}
          />
        </AccordionDetails>
      </Accordion>
      {
        //Plants
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Plants
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.plants?.map((p) => `[${p.id}] ${p.title} `)}
              singularName="plant"
              pluralName="plants"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomFastAsyncSelect
            onLoad={async (searchTerm) => {
              const result = await PromoItemsService.fetchPslAmdrPlantsV2({
                text: searchTerm,
                locationCompanyCodes: "",
                locationMarkets: "",
                locationZones: "",
                materialNumber: "",
              });
              return result.map(({ code, name }) => ({
                value: code,
                label: name,
              }));
            }}
            isMulti
            customDisplay={(option) => `[${option.value}] ${option.label}`}
            onLoadLimit={3}
            initialValue={state.plants?.map((m) => ({
              value: m.id,
              label: m.title,
            }))}
            onChange={(value) => {
              setState((prevState) => ({
                ...prevState,
                plants: value?.map((v) => ({ id: v.value, title: v.label })),
              }));
            }}
          />
        </AccordionDetails>
      </Accordion>
      {
        //Location
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Location
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={
                state.zones?.length
                  ? state.zones.map((p) => `[${p.id}] ${p.title} `)
                  : state.markets?.length
                  ? state.markets.map((p) => `[${p.id}] ${p.title} `)
                  : state.companies.map((p) => `[${p.id}] ${p.title} `)
              }
              singularName="location"
              pluralName="locations"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <LocationComponent
            isViewOnly={false}
            filters={state}
            setFilters={setState}
            companyCodes={companyCodes}
            marketState={marketState}
            zoneState={zoneState}
          />
        </AccordionDetails>
      </Accordion>
      {
        //Business Unit
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Business Unit
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.businessUnits.map((v) => v.title)}
              singularName="business unit"
              pluralName="business units"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomFastSelect
            isMulti={true}
            disabled={loadingBusinessUnits}
            initialValue={state.businessUnits.map((v) => ({
              value: v.id,
              label: v.title,
            }))}
            options={businessUnits.map((v) => ({
              value: v.id,
              label: v.name,
            }))}
            onChange={(value) => {
              setState((prevState) => ({
                ...prevState,
                businessUnits: value.map((v) => ({
                  id: v.value,
                  title: v.label,
                })),
              }));
            }}
          />
        </AccordionDetails>
      </Accordion>
      {
        //Strategic Buyer Email
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Strategic Buyer Email
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.strategicBuyers.map((v) => v.title)}
              singularName="buyer"
              pluralName="buyers"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <CustomFastAsyncSelect
            onLoad={async (searchTerm) => {
              const result = await PromoItemsService.fetchUserEmails({
                searchTerm,
              });
              return result.map((v) => ({
                value: v,
                label: v,
              }));
            }}
            isMulti
            onLoadLimit={4}
            initialValue={state.strategicBuyers?.map((b) => ({
              value: b.id,
              label: b.title,
            }))}
            onChange={(value) => {
              setState((prevState) => ({
                ...prevState,
                strategicBuyers: value.map((v) => ({
                  id: v.value,
                  title: v.label,
                })),
              }));
            }}
          />
        </AccordionDetails>
      </Accordion>
      {
        //Supplier
      }
      <Accordion>
        <AccordionSummary>
          <Grid container sx={{ width: "100%" }} spacing={2}>
            <Grid item xs={12}>
              <Typography
                sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}
              >
                Supplier
              </Typography>
              <Typography
                sx={{
                  color: "text.secondary",
                  ml: "auto",
                  width: "50%",
                  textAlign: "right",
                }}
              >
                <ItemsCounter
                  data={state.suppliers?.map((v) => `[${v.id}] ${v.title}`)}
                  singularName="supplier"
                  pluralName="suppliers"
                />
              </Typography>
            </Grid>
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sx={{ width: "100%" }} spacing={2}>
            <Grid item xs={12}>
              <Tabs
                value={state.supplierDescription[0]?.id}
                onChange={(e, newValue) => {
                  setState((prevState) => ({
                    ...prevState,
                    suppliers: [],
                    supplierDescription: [{ id: newValue, title: newValue }],
                  }));
                }}
              >
                <Tab value="parentVendor" label="Parent Vendor" />
                <Tab value="localChidVendor" label="Local/Child Vendor" />
              </Tabs>
            </Grid>
            <Grid item xs={12}>
              <CustomFastAsyncSelect
                isMulti={true}
                onLoadLimit={3}
                onLoad={(x: string) => {
                  if (state.supplierDescription[0]?.id === "localChidVendor") {
                    return PromoItemsService.getPslAmdrSuppliers({
                      searchTerm: x,
                      locationCompanyCodes: "",
                      isCreatePSL: true,
                    }).then((data) =>
                      data.map((el) => ({ value: el.id, label: el.name }))
                    );
                  } else if (
                    state.supplierDescription[0]?.id === "parentVendor"
                  ) {
                    return PromoItemsService.getPslAmdrSuppliersHierarchy({
                      searchTerm: x,
                    }).then((data) =>
                      data.map((el) => ({ value: el.id, label: el.name }))
                    );
                  } else {
                    return [];
                  }
                }}
                customDisplay={(option: { value: any; label: any }) =>
                  `${option.value} - ${option.label}`
                }
                initialValue={state.suppliers?.map((v) => ({
                  value: v.id,
                  label: v.title,
                }))}
                onChange={(v) => {
                  setState((prevState) => ({
                    ...prevState,
                    suppliers: v.map((v) => ({
                      id: v.value,
                      title: v.label,
                    })),
                  }));
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {
        //Supplier Status
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Supplier Status
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.statuses.map(
                (v) =>
                  ({
                    0: "All",
                    1: "Mandatory",
                    2: "Sole Source",
                    3: "Validated",
                    4: "Blank",
                  }[v.id])
              )}
              singularName="status"
              pluralName="status"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomFastSelect
                isMulti={true}
                initialValue={state.statuses.map((v) => ({
                  value: v.id,
                  label: {
                    0: "All",
                    1: "Mandatory",
                    2: "Sole Source",
                    3: "Validated",
                    4: "Blank",
                  }[v.id],
                }))}
                options={[
                  { value: 0, label: "All" },
                  { value: 1, label: "Mandatory" },
                  { value: 2, label: "Sole Source" },
                  { value: 3, label: "Validated" },
                  { value: 4, label: "Blank" },
                ]}
                onChange={(value) => {
                  setState((prevState) => ({
                    ...prevState,
                    statuses: value.map((v) => ({
                      id: v.value,
                      title: v.label,
                    })),
                  }));
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {
        //Supplier Diversity
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Supplier Diversity
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.supplierDiversity.map((v) => v.title)}
              singularName="group"
              pluralName="groups"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <SupplierDiversityFilterComponent
            initialValue={state.supplierDiversity.map((v) => ({
              value: v.id,
              label: v.title,
            }))}
            onChange={(selection) => {
              setState((prevState) => ({
                ...prevState,
                supplierDiversity: selection.map((v) => ({
                  id: v,
                  title: v,
                })),
              }));
            }}
          />
        </AccordionDetails>
      </Accordion>
      {
        //Dates
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Dates
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            {!state.pslCreationAndValidityDate ? (
              <></>
            ) : (
              <>
                {{
                  pslCreationDate: "Creation Date",
                  pslValidityDate: "Validity Date",
                }[state.pslCreationAndValidityDate?.type] || "Creation Date"}
                <br />
                {moment(state.pslCreationAndValidityDate.startDate).format(
                  "DD/MM/YYYY"
                )}{" "}
                -{" "}
                {moment(state.pslCreationAndValidityDate.endDate).format(
                  "DD/MM/YYYY"
                )}
              </>
            )}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container sx={{ width: "100%" }} spacing={2}>
            <Grid item xs={12}>
              <Tabs
                value={
                  state.pslCreationAndValidityDate?.type || "pslCreationDate"
                }
                onChange={(e, newValue) => {
                  setState((prevState) => ({
                    ...prevState,
                    pslCreationAndValidityDate: {
                      ...(prevState.pslCreationAndValidityDate || {
                        type: "pslCreationDate",
                        startDate: null,
                        endDate: null,
                      }),
                      type: newValue,
                    },
                  }));
                }}
              >
                <Tab value="pslCreationDate" label="Creation Date" />
                <Tab value="pslValidityDate" label="Validity Date" />
              </Tabs>
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="Start Date"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                value={moment(
                  state.pslCreationAndValidityDate?.startDate || null
                )}
                onChange={(value) => {
                  setState((prevState) => ({
                    ...prevState,
                    pslCreationAndValidityDate: {
                      ...(prevState.pslCreationAndValidityDate || {
                        type: "pslCreationDate",
                        startDate: null,
                        endDate: null,
                      }),
                      startDate: value ? value.toISOString() : null,
                    },
                  }));
                }}
              />
            </Grid>
            <Grid item xs={6}>
              <DatePicker
                label="End Date"
                slotProps={{
                  textField: {
                    size: "small",
                  },
                }}
                value={moment(
                  state.pslCreationAndValidityDate?.endDate || null
                )}
                onChange={(value) => {
                  setState((prevState) => ({
                    ...prevState,
                    pslCreationAndValidityDate: {
                      ...(prevState.pslCreationAndValidityDate || {
                        type: "pslCreationDate",
                        startDate: null,
                        endDate: null,
                      }),
                      endDate: value ? value.toISOString() : null,
                    },
                  }));
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {
        //Ways of Buying
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Ways of Buying
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.wayOfBuying.map((v) => v.title)}
              singularName="way"
              pluralName="ways"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <WaysOfBuyingComponent
            initialValue={state.wayOfBuying.map((v) => v.id)}
            onChange={(newValue) => {
              setState((prevState) => ({
                ...prevState,
                wayOfBuying: newValue.map((v) => ({
                  id: v,
                  title: waysTreeData.find((w) => w.value === v)?.title,
                })),
              }));
            }}
          />
        </AccordionDetails>
      </Accordion>
      {
        //Yearly Spend Estimation
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Yearly Spend Estimation
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            {state.fromSpend} - {state.toSpend} {state.currencySpend}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={4}>
              <TextField
                label="Min"
                fullWidth
                size="small"
                type="number"
                inputProps={{ min: 0 }}
                value={state.fromSpend}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    fromSpend: e.target.value,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                label="Max"
                fullWidth
                size="small"
                type="number"
                inputProps={{ min: 0 }}
                value={state.toSpend}
                onChange={(e) => {
                  setState((prevState) => ({
                    ...prevState,
                    toSpend: e.target.value,
                  }));
                }}
              />
            </Grid>
            <Grid item xs={4}>
              <FormControl size="small" fullWidth>
                <InputLabel id="filter-currency-label">Currency</InputLabel>
                <Select
                  placeholder="Currency"
                  fullWidth
                  disabled={loadingCurrencies}
                  value={state.currencySpend}
                  label="Currency"
                  id={"filter-currency"}
                  labelId="filter-currency-label"
                  onChange={(e) => {
                    console.log(e.target.value);
                    setState((prevState) => ({
                      ...prevState,
                      currencySpend: e.target.value,
                    }));
                  }}
                >
                  {currencies.map((currency) => {
                    return (
                      <MenuItem
                        key={currency.CurrencyKey}
                        value={currency.CurrencyKey}
                      >
                        {currency.Description}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      {
        //PSL Status
      }
      <Accordion>
        <AccordionSummary>
          <Typography sx={{ fontWeight: "bold", width: "50%", flexShrink: 0 }}>
            Promo Status
          </Typography>
          <Typography
            sx={{
              color: "text.secondary",
              ml: "auto",
              width: "50%",
              textAlign: "right",
            }}
          >
            <ItemsCounter
              data={state.pslStatuses.map(
                (v) =>
                  ({
                    0: "Pending",
                    1: "Approved",
                    2: "Rejected",
                    3: "Pending to Accept Changes",
                  }[v.id])
              )}
              singularName="status"
              pluralName="status"
            />
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <CustomFastSelect
                isMulti={true}
                menuPlacement="top"
                initialValue={state.pslStatuses.map((v) => ({
                  value: v.id,
                  label: {
                    0: "Pending",
                    1: "Approved",
                    2: "Rejected",
                    3: "Pending to Accept Changes",
                    4: "Deleted",
                    5: "Expired",
                  }[v.id],
                }))}
                options={[
                  { value: 0, label: "Pending" },
                  { value: 1, label: "Approved" },
                  { value: 2, label: "Rejected" },
                  { value: 3, label: "Pending to Accept Changes" },
                  { value: 4, label: "Deleted" },
                  { value: 5, label: "Expired" },
                ]}
                onChange={(value) => {
                  setState((prevState) => ({
                    ...prevState,
                    pslStatuses: value.map((v) => ({
                      id: v.value,
                      title: v.label,
                    })),
                  }));
                }}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
    </Drawer>
  );
};
