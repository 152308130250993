import * as yup from "yup";

export const IdTitlePairSchema = yup.object().shape({
  id: yup.number().required(),
  title: yup.string().required(),
});

export const IdNamePairSchema = yup.object().shape({
  id: yup.number().required(),
  name: yup.string().required(),
});

export const ShortUserSchema = yup.object().shape({
  id: yup.string().nullable().notRequired(),
  email: yup
    .string()
    .email("Enter valid email.")
    .required("This field is required"),
  username: yup.string().nullable().notRequired(),
});
