import { ListItem, ListItemAvatar, ListItemText, Tooltip } from "@mui/material";
import PUIAvatar from "./Avatar.component";
import React from "react";

export const PUIUser = ({
  email,
  name,
  onHoverText,
  photo,
  subtext,
  backgroundColor,
}: {
  name: string;
  email?: string;
  onHoverText?: string;
  photo?: string;
  subtext?: any;
  backgroundColor?: string;
}) => (
  <ListItem>
    <ListItemAvatar>
      <PUIAvatar
        name={name}
        src={photo}
        alt={email || name}
        backgroundColor={backgroundColor || "#a0aec0"}
        foregroundColor={"#fff"}
      />
    </ListItemAvatar>
    <ListItemText primary={name} secondary={subtext || email} />
  </ListItem>
);

export default PUIUser;
