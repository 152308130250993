import {
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  ListItemIcon,
  FormHelperText,
  Select,
} from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import AdminService from "modules/Admin/service/admin.service";

import { PromoItemRowSchema } from "modules/PromoItems/services/promoItems.schemas";
import React from "react";
import { toTitleCase } from "utils/utils";

export const PromoCategorySelect = (props: {
  error?: string;
  value: string;
  onChange: (e: any) => void;
}) => {
  const { data: promoCategories, isLoading: isLoadingCategories } = useQuery({
    queryKey: ["promo-categories/get-all"],
    queryFn: async () => {
      const response = await AdminService.getAllPromoCategories();
      return response;
    },
    initialData: [],
  });
  return (
    <FormControl fullWidth size="small">
      <InputLabel htmlFor="grouped-select">
        {`Promo Category 
          ${
            !(PromoItemRowSchema.describe().fields["promoCategoryId"] as any)
              ?.optional
              ? "*"
              : ""
          }`}
      </InputLabel>
      <Select
        defaultValue=""
        id="grouped-select"
        label="Promo Category"
        error={!!props.error}
        value={props.value}
        onChange={props.onChange}
        renderValue={(value) => (
          <>
            {toTitleCase(
              promoCategories.find((c) => c.id === value)?.name || ""
            )}
          </>
        )}
      >
        <ListSubheader>Type A</ListSubheader>
        {promoCategories
          .filter((c) => c.promoCategoryType === 0 && !c.isDeleted)
          .map((category) => (
            <MenuItem value={category.id} key={category.id}>
              <ListItemIcon
                sx={{
                  width: "2.5em",
                  height: "2.5em",
                  textAlign: "center",
                  p: 1,
                  "& svg": { maxWidth: "100%", maxHeight: "100%" },
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: category.icon,
                  }}
                />
              </ListItemIcon>
              {toTitleCase(category.name)}
            </MenuItem>
          ))}
        <ListSubheader>Type B</ListSubheader>
        {promoCategories
          .filter((c) => c.promoCategoryType === 1 && !c.isDeleted)
          .map((category) => (
            <MenuItem value={category.id} key={category.id}>
              <ListItemIcon
                sx={{
                  width: "2.5em",
                  height: "2.5em",
                  textAlign: "center",
                  p: 1,
                  "& svg": { maxWidth: "100%", maxHeight: "100%" },
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: category.icon,
                  }}
                />
              </ListItemIcon>
              {toTitleCase(category.name)}
            </MenuItem>
          ))}
        <ListSubheader>Type C</ListSubheader>
        {promoCategories
          .filter((c) => c.promoCategoryType === 2 && !c.isDeleted)
          .map((category) => (
            <MenuItem value={category.id} key={category.id}>
              <ListItemIcon
                sx={{
                  width: "2.5em",
                  height: "2.5em",
                  textAlign: "center",
                  p: 1,
                  "& svg": { maxWidth: "100%", maxHeight: "100%" },
                }}
              >
                <div
                  dangerouslySetInnerHTML={{
                    __html: category.icon,
                  }}
                />
              </ListItemIcon>
              {toTitleCase(category.name)}
            </MenuItem>
          ))}
      </Select>
      {!!props.error && <FormHelperText error>{props.error}</FormHelperText>}
    </FormControl>
  );
};

export default PromoCategorySelect;
