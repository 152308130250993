import * as yup from "yup";

export const PromoItemRowSchema = yup.object().shape({
  deleted: yup.boolean(),
  currencyCode: yup.string().required("Currency is required"),
  itemDescription: yup.string().required("this field is required"),
  keyWords: yup
      .array()
      .of(yup.string().required())
      .min(1, "At least one keyword is required")
      .required("This field is required."),
  languageId: yup.string().required("this field is required"),
  languageTag: yup.string().nullable(),
  manufacturerName: yup.string().nullable(),
  manufacturerPartNumber: yup.string().nullable(),
  marketContactName: yup.string().nullable(),
  matGrp: yup.string().nullable(),
  materialId: yup.string().nullable(),
  materialName: yup.string().nullable(),
  materialNumber: yup.string().required("this field is required"),
  parentMaterialId: yup.string().nullable(),
  promoCategoryId: yup.string().required("this field is required"),
  shortDescription: yup
    .string()
    .notRequired()
    .when("deleted", {
      is: false,
      then: (schema) => schema.required("this field is required"),
    }),
  spendCat1Code: yup.string().nullable(),
  spendCat1DisplayValue: yup.string().nullable(),
  spendCat1Id: yup.string().nullable(),
  spendCat2Code: yup.string().nullable(),
  spendCat2DisplayValue: yup.string().nullable(),
  spendCat2Id: yup.string().nullable(),
  spendCat3Code: yup.string().nullable(),
  spendCat3DisplayValue: yup.string().nullable(),
  spendCat3Id: yup.string().nullable(),
  supplierLeadTime: yup.string().nullable(),
  supplierPartId: yup.string().nullable(),
  unitOfMeasure: yup.string().required("this field is required"),
  minimumOrderQuantity: yup.number().required("this field is required").min(1, "Quantity must be greater than 0"),
  minimumPurchaseQuantity: yup.number().required("this field is required").min(1, "Quantity must be greater than 0"),
  waysOfBuying: yup.array().of(yup.number()),
  mediaFiles: yup.array().of(
    yup.object().shape({
      fileId: yup.string().nullable().notRequired(),
      mimeType: yup.string(),
      originalFileName: yup.string(),
      persistedFileNameInAzureStorage: yup.string(),
      url: yup.string(),
    })
  ),
  priceRanges: yup.array().of(
    yup.object().shape({
        quantity: yup
            .number()
            .required("This field is required")
            .min(1, "Quantity must be greater than 0")
            .test(
                "threshold",
                "Quantity should higher or equal to the Minimum Order Quantity (MOQ)",
                (value, context) => {
                    return value >= context.from[1].value.minimumOrderQuantity;
                }
            )
            .test(
                "unique-quantity",
                "Quantity values must be unique",
                function (value, context) {
                    const quantities = context.from[1].value.priceRanges.map((range) => range.quantity);
                    return quantities.filter((q) => q?.toString() === value?.toString()).length === 1;
                }
            ),
        price: yup.number().required("This field is required").min(1, "Price must be greater than 0"),
        pricePer: yup.number().required("This field is required").min(1, "Price Per must be greater than 0"),
    })
  ),
});
