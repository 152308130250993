import React from "react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ThemeProvider } from "@mui/material";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.bubble.css';
import 'quill/dist/quill.snow.css';

// Local imports
// import CategoriesContainer from "./modules/PromoItems/containers/categories.container";
import { Layout } from "./modules/Layout/containers/layout.container";
import CreatePromoItemContainer from "./modules/PromoItems/containers/create.container";
import ImportPromoItemsContainer from "./modules/PromoItems/containers/import.container";
import ListContainer from "./modules/PromoItems/containers/list.container";
import { muiTheme } from "./app.theme";
import "./App.css";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import LandingView from "modules/Layout/views/landing.view";
import SuppliersListContainer from "modules/PromoItems/containers/suppliers.container";
import BrowseContainer from "modules/PromoItems/containers/browse.container";
import TasksContainer from "modules/Tasks/containers/tasks.container";
import ErrorBoundary from "modules/UI/components/ErrorBoundary.component";
import { useFeatures } from "modules/FeatureManagement/service/featureManagement.hooks";
import MaintenanceView from "modules/Layout/views/maintenance.view";
import NotFoundView from "modules/Layout/views/notFound.view";
import CampaignsListContainer from "modules/Campaigns/containers/list.container";
import CreateCampaignContainer from "modules/Campaigns/containers/details.container";
import PromoCategoriesContainer from "modules/Admin/containers/PromoCategories.container";
const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    // path: "/",
    element: <Layout />,
    errorElement: <NotFoundView />,
    children: [
      {
        path: "/",
        element: <LandingView />,
      },
      {
        path: "/browse",
        element: <BrowseContainer />,
      },
      {
        path: "/tasks",
        element: <TasksContainer />,
      },
      // {
      //   path: "/auth",
      //   element: <CategoriesContainer />,
      // },
      {
        path: "/suppliers",
        element: <ListContainer />,
      },
      {
        path: "/suppliers/new",
        element: <CreatePromoItemContainer />,
      },
      {
        path: "/suppliers/import",
        element: <ImportPromoItemsContainer />,
      },
      {
        path: "/suppliers/:promoId/edit",
        element: <CreatePromoItemContainer />,
      },
      {
        path: "/campaigns",
        element: <CampaignsListContainer />,
      },
      {
        path: "/campaigns/new",
        element: <CreateCampaignContainer />,
      },
      {
        path: "/campaigns/:campaignId",
        element: <CreateCampaignContainer />,
      },
      {
        path: "/admin/promo-categories",
        element: <PromoCategoriesContainer />,
      },
    ],
  },
]);

function App() {
  const features = useFeatures();
  return features["maintenance-mode"] ? (
    <MaintenanceView />
  ) : (
    <QueryClientProvider client={queryClient}>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <ToastContainer />
        <RouterProvider router={router} />
        <ReactQueryDevtools
          initialIsOpen={false}
          buttonPosition={"bottom-left"}
        />
      </LocalizationProvider>
    </QueryClientProvider>
  );
}

export default App;
