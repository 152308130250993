import { useMutation } from "@tanstack/react-query";
import React, { useContext } from "react";
import { UserService } from "../service/user.service";
import { IUserRole } from "../service/user";
import { MenuItem, Select } from "@mui/material";
import { ActiveUserContext, useActiveUser } from "../activeUser";
import { useFeatures } from "modules/FeatureManagement/service/featureManagement.hooks";

export const UserRoleSwitcher = () => {
  const features = useFeatures();
  const { activeUser, fetchUser } = useContext(ActiveUserContext);
  const { mutateAsync: changeRole } = useMutation({
    mutationKey: ["changeRole"],
    mutationFn: (pslPersona: IUserRole) =>
      UserService.changeUserHardcodedPslPersona({ pslPersona }),
  });
  return features["update-own-role"] ? (
    <Select
      fullWidth
      labelId="demo-select-small-label"
      id="demo-select-small"
      value={activeUser.userSapPersonaPslOnly}
      size="small"
      onChange={(e) => {
        changeRole(e.target.value).then(() => {
          fetchUser();
        });
      }}
    >
      {[
        "At Market",
        "Above Market",
        "Requestor",
        "Factory Analyst",
        "Operational Buyer",
        "PPA At Market",
        "PPA Above Market",
        "View Only",
        "Supplier",
      ].map((role, index) => {
        return <MenuItem value={index}>{role}</MenuItem>;
      })}
    </Select>
  ) : (
    <>
      {
        [
          "At Market",
          "Above Market",
          "Requestor",
          "Factory Analyst",
          "Operational Buyer",
          "PPA At Market",
          "PPA Above Market",
          "View Only",
          "Supplier",
        ][activeUser.userSapPersonaPslOnly]
      }
    </>
  );
};

export default UserRoleSwitcher;
