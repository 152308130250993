import { AttachFile } from "modules/UI/components/AttachFile.component";
import { Attachment } from "modules/UI/components/Attachment.component";
import AddIcon from "@mui/icons-material/Add";
import { Box, List } from "@mui/material";
import moment from "moment";
import { useState } from "react";
import React from "react";
import { Accept, FileRejection, useDropzone } from "react-dropzone";
import { useConfirmation } from "../hooks/useConfirmation.hook";

type Attachment = {
  creationDate: string;
  fileName: string;
  id: string | number;
  uploadedByUserEmail: string;
};

export const Attachments = (props: {
  attachments: Attachment[];
  enableDelete?: boolean;
  enableUpload?: boolean;
  isDeleting?: boolean;
  isLoading?: boolean;
  isUploading?: boolean;
  accept?: Accept;
  readOnly?: boolean;
  multiple?: boolean;
  fullHeight?: boolean;
  minSize?: number;
  maxSize?: number;
  maxFiles?: number;
  placeholder?: string | React.Component | JSX.Element;
  onAttach?: (files: any) => void;
  onClick?: (files: any) => void;
  onDelete?: (attachment: Attachment) => void;
}) => {
  const {
    attachments,
    enableDelete,
    enableUpload,
    isDeleting,
    isLoading,
    isUploading,
    accept,
    multiple,
    minSize,
    maxSize,
    maxFiles,
    onAttach,
    onClick,
    onDelete,
  } = props;
  const deleteConfirmation = useConfirmation({
    title: "Delete attachment",
    description: "This action will delete this file forever.",
    submitButtonText: "Yes, delete it.",
    cancelButtonText: "No, keep it.",
  });
  const [deleteAttachment, setDeleteAttachment] = useState(null);
  const [fileRejections, setFileRejections] = useState<FileRejection[]>(null);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    noClick: true,
    onDrop: (acceptedFiles, fileRejections, dropEvent) => {
      setFileRejections(fileRejections);
      onAttach(acceptedFiles);
    },
    accept,
    multiple,
    maxSize,
    minSize: minSize || 1,
    maxFiles,
  });

  return isLoading ? (
    <>loading attachments</>
  ) : (
    <Box
      {...getRootProps()}
      sx={{
        transition: ".2s ease-out",
        margin: props.fullHeight ? "0" : "1rem 0",
        height: props.fullHeight ? "100%" : "auto",
        border: isDragActive
          ? "2px dashed currentColor"
          : "2px solid transparent",
      }}
    >
      {deleteConfirmation.JSX}
      <List>
        {/* <NSPConfirmation
        description="This action will delete this file forever."
        confirmLabel="Yes, delete it."
        cancelLabel="No, keep it."
        isLoadingConfirm={isDeleting}
        visible={!!deleteAttachment}
        onCancel={() => {
          setDeleteAttachment(null);
        }}
        onConfirm={() => {
          onDelete(deleteAttachment);
          setDeleteAttachment(null);
        }}
      /> */}
        {attachments?.map((attachment) => (
          <Attachment
            key={attachment.id}
            fileName={attachment.fileName}
            secondary={`uploaded ${moment
              .utc(attachment.creationDate)
              .fromNow()} by ${attachment.uploadedByUserEmail}`}
            onDelete={
              !enableDelete || isDeleting
                ? null
                : () =>
                    deleteConfirmation
                      .requestConfirmation()
                      .then(() => {
                        onDelete(attachment);
                        setDeleteAttachment(null);
                      })
                      .catch((e) => {
                        setDeleteAttachment(null);
                      })
            }
            onClick={() => onClick(attachment)}
          />
        ))}
        {enableUpload && (
          <AttachFile
            isLoading={isUploading}
            // icon={<AddIcon />}
            onAttach={onAttach}
            inputProps={{ ...getInputProps() }}
          />
        )}
      </List>
    </Box>
  );
};
