import { createDummyQueryService, createQueryService } from "utils/factories";
import materialsV2 from "./dummyData/materialsV2";
import SpendCategories from "./dummyData/spendCategories";
import { SupplierContactType } from "./generic.types";

export default class PromoCatalogService {
  static getSupplierContacts = createQueryService<
    SupplierContactType[],
    { supplierId: string }
  >(({ supplierId }) => `/api/SupplierContact/${supplierId}`);
}
